import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,//import here} from "./utils/interact.js";
  mintMultiple,
  checkWizards
} from "./utils/interact.js";
import FrostBlade from 'D:/NFT_development/front_end/WizarDAO_minter/nft-minter-tutorial-main/minter-starter-files/src/WIZ.gif'

const Minter = (props) => {

  

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  let [name, setName] = useState("");
  const [wizstatus, setWizStatus] = useState("");
 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);
    
        
    addWalletListener(); 
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => { //TODO: implement
      const { status } = await mintNFT();
    setStatus(status);  
  };

  const onMintLoopPressed = async () => { //TODO: implement
      const { status } = await mintMultiple();
    setStatus(status);  
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkWizards(name);
    setWizStatus(wizstatus);  
  };

  return (
    <div className="Minter">

      <h1 id="title">Claim WizarDAO NFTs</h1>
      <h3> Every PixaWizard NFT you own entitles you to 1 free WizarDAO token claim.</h3>
      <h5> Please read the terms and conditions you agree to by using PixaLyfe NFT websites: pixalyfenft.com/terms. The terms are also included at the bottom of this webpage. Disclaimer: Use these interfaces at your own risk! </h5>

      <br></br>      
      <h4 id="subheading"> Main Pixalyfe website:</h4>
      <div id = "webbutton">
      <form id="webbutton" action="https://pixalyfenft.com">
        <input id="webbutton" type="submit" value="PixaLyfe" />
      </form> 
      </div>
      <br></br>  
      <h4 id="subheading"> WizarDAO voting interface:</h4>     
      <div id = "webbutton">
      <form id="webbutton" action="https://wizardao.eth.limo">
        <input id="webbutton" type="submit" value="WizarDAO" />
      </form> 
      </div>
      <br></br>
     <br></br>
      <h3 id="title">Enter PixaWizard TokenID to check claim status: </h3>
      <h5 id = "header33">Make sure you are entering the token ID, NOT the PixaWizard #. You can check the token ID for a PixaWizard NFT under the "details" tab on OpenSea. </h5>


        <input id="entry"
          type="number"
          placeholder="Token ID"
          onChange={(event) => setName(event.target.value)}
        />
      <button id="checkButton" onClick={onCheckPressed}>
        Check
      </button>

        <p id="wizstatus">
        {wizstatus}
              </p>




      <h2 id="title">Connect your wallet and claim your WizarDAO token(s).</h2>

      <br></br>

      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <div>
      <img src={FrostBlade} alt="Image" height={300} width={300}/>
      </div>

          <br></br>
      <button id="mintButton" onClick={onMintPressed}>
        Mint One
      </button>
      
      <button id="mintButton2" onClick={onMintLoopPressed}>
        Mint All
      </button>
      <p id="status">
        {status}
      </p>
      <h3 id="title">You may either mint 1 token at a time, or mint all WizarDAO tokens you are entitled to.</h3>

      <br></br>

      <br></br>

      <br></br>


      <h5 id="subheading"> Terms and Conditions (applies to all PixaLyfe sites): Your purchase of PixaLyfe NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from PixaLyfe is meant for entertainment purposes only. PixaLyfe makes no warranties regarding future value, express or implied, to the public or to buyers. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. PixaLyfe NFTs and $PIXA tokens are not currency and do not convey ownership in PixaLyfe or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in PixaLyfe, future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your PixaLyfe NFT. There is no guarantee that the PixaVerse platform will actually be developed, although we guarantee that it is our intention to do so. </h5>

      <br></br>
      <br></br>
      <br></br>

    </div>
  );
};



export default Minter;
